import { request } from "@api";
import { IMyApolloClubLayout } from "@api/interfaces/apolloClubLayout";
import { IBenefit } from "@api/interfaces/benefitLayouts";
import { ICloseAccountLayout } from "@api/interfaces/closeAccountLayout";
import { IGenericPageLayout } from "@api/interfaces/genericPageLayout";
import { IGoodbyeLayout } from "@api/interfaces/goodbyeLayout";
import {
  IPrivateHomePageLayout,
  IPublicHomePageLayout,
} from "@api/interfaces/homepageLayout";
import { ILocationPageLayout } from "@api/interfaces/locationPageLayout";
import IMenuItemLayout from "@api/interfaces/menuLayout";
import { IMyGiftCardPageLayout } from "@api/interfaces/myGiftCardPageLayout";
import { IBusinessNewsletter } from "@api/interfaces/newsLetterLayout";
import { INewslettersPageLayout } from "@api/interfaces/newslettersPageLayout";
import { IPageSection } from "@api/interfaces/pageLayout";
import { ApiPath } from "@api/paths";
import { ApiRequest, RequestResponse } from "@api/types";

const fetchMyInfoPage: ApiRequest<
  RequestResponse<IPageSection[]>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.myInfo, canFail: true });

const fetchLocationsPage: ApiRequest<
  RequestResponse<ILocationPageLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.entities.locations, canFail: true });

const fetchPublicHomePage: ApiRequest<
  RequestResponse<IPublicHomePageLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.publicHome, canFail: true });

const fetchPrivateHomePage: ApiRequest<
  RequestResponse<IPrivateHomePageLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.privateHome, canFail: true });

const fetchMyGiftCardsPage: ApiRequest<
  RequestResponse<IMyGiftCardPageLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.giftCard, canFail: true });

const fetchTermsPage: ApiRequest<
  RequestResponse<IGenericPageLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.terms, canFail: true });

const fetchMenu: ApiRequest<RequestResponse<IMenuItemLayout[]>, void> = async (
  options,
) =>
  request({ ...options, api: ApiPath.CMS.entities.burgerMenu, canFail: true });

const fetchBusinesses: ApiRequest<RequestResponse<IBenefit[]>, void> = async (
  options,
) =>
  request({ ...options, api: ApiPath.CMS.entities.businesses, canFail: true });

const fetchMyApolloClubPage: ApiRequest<
  RequestResponse<IMyApolloClubLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.myClub, canFail: true });

const fetchCloseAccountPage: ApiRequest<
  RequestResponse<ICloseAccountLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.closeAccount, canFail: true });

const fetchGoodbyePage: ApiRequest<
  RequestResponse<IGoodbyeLayout>,
  void
> = async (options) =>
  request({ ...options, api: ApiPath.CMS.pages.goodbye, canFail: true });

const fetchNewslettersPage: ApiRequest<
  RequestResponse<INewslettersPageLayout>,
  void
> = async (options) =>
  request({
    ...options,
    api: ApiPath.CMS.pages.newsletters,
    canFail: true,
  });

const fetchBusinessNewsletters: ApiRequest<
  RequestResponse<IBusinessNewsletter[]>,
  void
> = async (options) =>
  request({
    ...options,
    api: ApiPath.CMS.entities.businessNewsletters,
    canFail: true,
  });

const CMSController = {
  fetchMyInfoPage,
  fetchLocationsPage,
  fetchPublicHomePage,
  fetchPrivateHomePage,
  fetchMyGiftCardsPage,
  fetchTermsPage,
  fetchMenu,
  fetchBusinesses,
  fetchMyApolloClubPage,
  fetchNewslettersPage,
  fetchBusinessNewsletters,
  fetchCloseAccountPage,
  fetchGoodbyePage,
};

export default CMSController;
