import { isPresent } from "@apl-digital/utils";
import config from "@constants/config";

const insertGTM = () => {
  const gtmCode = config.apiConfig.google.tagManager?.code;

  if (!isPresent(gtmCode)) {
    return;
  }

  const body = document.querySelector("#gtm-body");

  const headCodeGtm =
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','` +
    gtmCode +
    `');`;

  const bodyCodeGtm =
    `
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=` +
    gtmCode +
    `"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->`;

  const newScript = document.createElement("script");
  const firstScript = document.querySelectorAll("script")[0];
  newScript.text = headCodeGtm;
  firstScript?.parentNode?.insertBefore(newScript, firstScript);

  if (body) {
    body.innerHTML = bodyCodeGtm;
  }
};

export default insertGTM;
